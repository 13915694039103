body {
	margin: 0;
	font-family: 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	box-sizing: border-box;
	height: 100vh;
	width: 100vw;
}



code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

/*
::placeholder class is duplicated because the first one only apply to MUI textField (TextArea) and the second one for our Input
*/
.inherited {
	color: gray !important;
	font-style: italic;
	::placeholder {
		color: gray !important;
		font-style: italic;
		opacity: 1 !important;
	}
}

.inherited::placeholder {
	color: gray !important;
	font-style: italic;
	opacity: 1 !important;
}
