#include-exclude-wrapper {
    container-type: inline-size;
}

@container (max-width: 185px) {
    #mui-form-group-include-exclude {
        flex-direction: column;
        align-items: center;
        gap: 0 !important;
    }
}
